import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { findParticipantById, updateParticipantRegistration } from '../services/RegistrationService';
import { serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

/**
 * FitbitCallback component handles the callback from Fitbit's OAuth process.
 * It retrieves the authorization code and state from the URL, fetches the participant data,
 * and allows the user to agree to terms and proceed.
 */
function FitbitCallback() {
  const code = new URLSearchParams(window.location.search).get('code');
  const state = new URLSearchParams(window.location.search).get('state');
  const pid = document.cookie.replace(/(?:(?:^|.*;\s*)pid\s*=\s*([^;]*).*$)|^.*$/, "$1");
  const participantId = document.cookie.replace(/(?:(?:^|.*;\s*)participantId\s*=\s*([^;]*).*$)|^.*$/, "$1");

  // State variables
  const [participant, setParticipant] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  useEffect(() => {
    // Check if code and state are present
    if (!code || !state || !participantId || !pid) {
      setError('Missing code, state, or participant data.');
      addAlert('error', 'Missing code, state, or participant data.');
      return;
    }

    fetchParticipant(participantId).then((data) => {
      if (data) {
        setParticipant(data);
        updateRegistrationStep(3); // Update registration step when callback is received
      } else {
        setError('Failed to fetch participant data.');
        addAlert('error', 'Failed to fetch participant data.');
      }
    }).catch((err) => {
      setError('Error fetching participant data.');
      addAlert('error', 'Error fetching participant data.');
    });
  }, []);

  /**
   * Fetches the participant data from the database.
   * @returns {Promise<Object>} The participant data or null if not found.
   */
  const fetchParticipant = async (participantId) => {
    setLoading(true);
    try {
      const participant = await findParticipantById(participantId);
      if (participant && participant.registrationStep >= 2) {
        setParticipant(participant);
        return participant;
      } else {
        throw new Error('Participant data invalid or registration step too low.');
      }
    } catch (err) {
      error(`Failed to fetch participant data: ${err.message}`);
      setError('Failed to fetch participant data.');
      addAlert('error', 'Failed to fetch participant data.');
    } finally {
      setLoading(false);
    }
    return null;
  };

  /**
   * Updates the registration step of the participant and handles fallback if update fails.
   * @param {number} step - The registration step to update to.
   */
  const updateRegistrationStep = async (step) => {
    try {
      const res = await updateParticipantRegistration(participantId, step);
      if (!res) throw new Error(`Failed to update registration step to ${step}`);
      setParticipant(res);
    } catch (err) {
      error(`Error updating registration step to ${step}: ${err.message}`);
      setError(`Error updating registration step to ${step}`);
      addAlert('error', `Error updating registration step to ${step}`);

      // Attempt to set an intermediate step indicating the error
      try {
        await updateParticipantRegistration(participantId, step + 0.5);
      } catch (fallbackErr) {
        error(`Failed to update fallback registration step: ${fallbackErr.message}`);
        addAlert('error', `Failed to update fallback registration step.`);
      }
    }
  };

  /**
   * Completes the registration process, updates registration step, and redirects back to registration page.
   */
  const completeRegistration = async () => {
    setLoading(true);
    try {
      const participantAdditionalData = {
        completed: true,
        completedAt: serverTimestamp()
      };

      const res = await updateParticipantRegistration(participantId, 5, participantAdditionalData);
      if (!res) throw new Error('Error updating the registration step to 5');
      navigate(`/prolificRegistration/${participant?.projectId}/pId/${participant?.pid}`);
    } catch (err) {
      error(`Error completing registration: ${err.message}`);
      setError('Error completing registration.');
      addAlert('error', 'Error completing registration.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the completion of Fitbit authorization and updates the registration step accordingly.
   */
  const handleCompleteFitbitAuth = async () => {
    if (loading) return;

    setLoading(true);
    if (!agreeTerms) {
      addAlert("error", "Please agree to the terms and conditions");
      setLoading(false);
      return;
    }

    try {
      const handleAuthorizationRedirect = httpsCallable(functions, 'handleAuthorizationRedirect');
      const res = await handleAuthorizationRedirect({ code: code, state: state });

      if (res?.data?.fitbitData?.access_token) {
        await completeRegistration();
        addAlert("success", "Fitbit authorization completed successfully");
      } else {
        throw new Error('Fitbit authorization failed');
      }
    } catch (err) {
      error(`Fitbit authorization failed: ${err.message}`);
      setError('Fitbit authorization failed.');
      addAlert('error', 'Fitbit authorization failed.');
    } finally {
      setLoading(false);
      navigate(`/prolificRegistration/${participant?.projectId}/pId/${participant?.pid}`);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card>
        <CardHeader title="Fitbit Consent Form" />
        <CardContent>
          {(loading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}
          <Typography variant="body1">
            Hereby I give my consent to share my Fitbit wearable data with Jamasp.
            I understand that my data will be used for research purposes and will be kept confidential.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            I understand that I can withdraw my consent at any time.
          </Typography>
          <FormControlLabel sx={{ mt: 3 }}
            control={<Checkbox value="accept" required
              onChange={() => setAgreeTerms(!agreeTerms)} color="primary" checked={agreeTerms} />}
            label="By clicking this box, I accept the Terms of Service and Privacy Policy."
          />
        </CardContent>
        <CardActions>
          <Button disabled={!agreeTerms || loading} variant="contained" color="primary" onClick={handleCompleteFitbitAuth}>
            Complete Authorization
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default FitbitCallback;