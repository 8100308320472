// src/context/RegistrationContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { findOrCreateNewParticipant, findParticipantById, updateParticipantRegistration } from '../services/RegistrationService';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { useAlert } from './AlertContext'; // Assume you have an AlertContext for notifications

// Create the context
const RegistrationContext = createContext();

// Custom hook to use context
export const useRegistration = () => useContext(RegistrationContext);
// Provider component
export const RegistrationProvider = ({ children }) => {
  // Retrieve URL parameters
  const { projectId, pId } = useParams();

  const [pid, setPid] = useState(!!pId ? pId : null);
  const [participantId, setParticipantId] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { addAlert } = useAlert();

  // Load participant data on mount
  useEffect(() => {
    const loadParticipantData = async () => {
      setLoading(true);
      const cookiePid = document.cookie.replace(/(?:(?:^|.*;\s*)pid\s*=\s*([^;]*).*$)|^.*$/, "$1");
      const cookieParticipantId = document.cookie.replace(/(?:(?:^|.*;\s*)participantId\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (cookiePid && cookieParticipantId) {
        setPid(cookiePid);
        setParticipantId(cookieParticipantId);
        try {
          const participantData = await findParticipantById(cookieParticipantId);
          if (participantData) {
            setParticipant(participantData);
            setActiveStep(participantData.registrationStep);
          }
        } catch (err) {
          setError('Error in finding participant');
          addAlert('error', 'Error in finding participant');
        }
      }
      setLoading(false);
    };

    loadParticipantData();
  }, []);

  // Function to handle Prolific ID submission
  const storePid = async () => {
    if (!pid) {
      addAlert('error', 'Please enter your Prolific ID');
      return;
    }

    setLoading(true);
    try {
      const newParticipantId = await findOrCreateNewParticipant(pid, projectId);
      setParticipantId(newParticipantId);
      const participantData = await findParticipantById(newParticipantId);

      if (participantData) {
        setParticipant(participantData);
        setActiveStep(participantData.registrationStep);
      }

      document.cookie = `pid=${pid}; path=/`;
      document.cookie = `participantId=${newParticipantId}; path=/`;
      setSuccess('Successfully created new participant');
    } catch (err) {
      setError('Error in creating new participant');
      addAlert('error', 'Error in creating new participant');
    }
    setLoading(false);
  };

  // Function to initiate Fitbit authorization
  const initiateFitbitAuth = async () => {
    setLoading(true);
    try {
      const generateFitbitAuthData = httpsCallable(functions, 'generateVerifierAndChallenge');
      const result = await generateFitbitAuthData({ pid, participantId });

      if (result?.data?.redirectUri) {
        window.location.href = result.data.redirectUri;
      } else {
        throw new Error('Failed to generate Fitbit authorization URL');
      }
    } catch (err) {
      setError('Error in generating verifier and challenge');
      addAlert('error', 'Error in generating verifier and challenge');
    } finally {
      setLoading(false);
    }
  };

  // Update the registration step
  const updateRegistrationStep = async (step, additionalData = {}) => {
    setLoading(true);
    try {
      const updatedParticipant = await updateParticipantRegistration(participantId, step, additionalData);
      if (updatedParticipant) {
        setParticipant(updatedParticipant);
        setActiveStep(step);
      }
    } catch (err) {
      setError('Error updating registration step');
      addAlert('error', 'Error updating registration step');
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegistrationContext.Provider
      value={{
        pid,
        setPid,
        projectId,
        participantId,
        participant,
        activeStep,
        loading,
        setLoading,
        error,
        setError,
        success,
        setSuccess,
        storePid,
        initiateFitbitAuth,
        updateRegistrationStep,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};